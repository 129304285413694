<template>
  <div class="column is-3 is-gallery">
    <div class="card">
      <v-lazy-image
        width="250"
        height="250"
        class="image"
        :src="require(`@/assets/images/gallery/${image}`)"
        :alt="imageAlt"
      />
    </div>
  </div>
</template>

<script>
import VLazyImage from 'v-lazy-image';

export default {
  name: 'GalleryItem',
  props: ['image', 'imageAlt'],
  components: {
    VLazyImage,
  },
};
</script>

<style lang="scss" scoped>
.card {
  background-color: $white;
  width: 100%;
  height: 250px;
  display: flex;
  flex-direction: column;
  position: relative;
  filter: opacity(60%) grayscale(100%);
  -webkit-filter: opacity(60%) grayscale(100%);
  transition: filter ease-in-out 0.3s;
  cursor: pointer;
  text-align: left;

  &:hover {
    filter: opacity(100%);
    transition: all ease-in-out 0.3s;
  }
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
}

@media only screen and (min-width: 769px) {
  .is-gallery {
    padding: 0;

    &:nth-child(4n + 5) {
      padding-left: 0.75rem;
    }

    &:nth-child(4n + 4) {
      padding-right: 0.75rem;
    }
  }
}

@media only screen and (max-width: 768px) {
  .is-gallery {
    padding: 0 1rem;
  }
}
</style>
